import React from "react";
import Email from "../assets/img/email.png";
import Phone from "../assets/img/phone.png";
import Place from "../assets/img/place.png";
import logo from "../assets/img/logo.png";

const footer = () => {
    return (
        <footer className="homeFooter">
            <div className="copyright pc">© 2021-2025 White Blocktech 白塊科技 All Rights Reserved</div>
            <div className="copyright ipad">
                <img className="footerLogo" src={logo} alt="logoImg"></img>

                <div className="contactBox">
                    <div className="title">Contact Us</div>
                    <div className="place item">
                        <img className="me-2" src={Place} alt="place"></img>長安東路二段49號7樓之一
                    </div>
                    <div className="email item">
                        <img className="me-2" src={Email} alt="Email"></img>service@whiteblocktech.com
                    </div>
                    <div className="phone item">
                        <img className="me-2" src={Phone} alt="Phone"></img>02-77560767
                    </div>
                    <div className="text">© 2021-2025 White Blocktech 白塊科技</div>
                    <div className="text"> All Rights Reserved</div>
                </div>
            </div>
        </footer>
    );
};
export default footer;
